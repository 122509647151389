<template>
    <div class="pd-10 h-calc-60 words-main">
        <div class="form-main">
            <div class="form-item">
                <el-form :model="form" :inline="true">
                    <el-form-item label="是否含有敏感词">
                        <el-select v-model="form.selected" @change="says">
                            <el-option :label="item.name" :value="item.value" v-for="(item, index) in selectList" :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否禁用">
                        <el-select v-model="form.disabled" @change="says">
                            <el-option :label="item.name" :value="item.value"  v-for="(item, index) in disableList" :key="index" />
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="form-item">
                <el-input
                    v-model.trim="phone" placeholder="输入手机号" clearable style="width: 300px;"
                    @clear="says"
                    @keydown.enter.native="says"
                >
                    <template #append><el-button :icon="Search" @click="says"  /></template>
                </el-input>
            </div>

        </div>
        <div class="data-main">
            <el-table :data="tableData" height="calc(100% - 60px)">
                <el-table-column label="序号" width="100" align="center">
                    <template #default="scope">
                        {{indexSort(scope.$index)}}
                    </template>
                </el-table-column>
                <el-table-column label="昵称" prop="nickname" width="120" align="center"/>
                <el-table-column label="手机号" prop="mobile" width="200" align="center" />
                <el-table-column label="留言" align="left">
                    <template #default="scope">
                        <el-popover placement="top-start" trigger="hover">
                            <template #default>
                                <p>{{scope.row.content}}</p>
                            </template>
                            <template #reference>
                                <el-tag>{{ subContent(scope.row.content) }}</el-tag>
                            </template>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="过滤后的留言"  align="left">
                    <template #default="scope">
                        <el-popover placement="top-start" trigger="hover">
                            <template #default>
                                <p>{{scope.row.content_after}}</p>
                            </template>
                            <template #reference>
                                <el-tag type="success">{{ subContent(scope.row.content_after) }}</el-tag>
                            </template>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="是否含有敏感词" width="150" align="center">
                    <template #default="scope">
                        {{scope.row.is_sensitive === 0 ? '否' : '是'}}
                    </template>
                </el-table-column>
                <el-table-column label="是否禁用" width="100">
                    <template #default="scope">
                        <el-switch
                            v-model="scope.row.status"
                            inactive-text="否"
                            active-text="是" inline-prompt
                            @change="closeWords(scope.row)"
                        ></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="发布时间" prop="create_time" width="200" align="center" />
            </el-table>
            <page-component
                :style="`margin-top: 10px;`"
                :page="pageObj.page"
                :limit="pageObj.limit"
                :total="pageObj.total"
                @change="pageChange"
            ></page-component>
        </div>

    </div>
</template>
<script setup>
import {onMounted, reactive, ref, toRefs} from "vue"
import {sayLists, disabledWords} from "@/utils/apis";
import {Search} from '@element-plus/icons-vue';
import PageComponent from "@/components/common/PageComponent";
import {ElMessage} from "element-plus";
const tableData = ref([])
const phone = ref('')
const selectList = ref([
    {name: '全部', value: ''},
    {name: '是', value: 1},
    {name: '否', value: 0}
])
const disableList = ref([
    {name: '全部', value: ''},
    {name: '是', value: 1},
    {name: '否', value: 0}
])
const form = reactive({
    selected: '',
    disabled: ''
})
const formState = toRefs(form)
const pageObj = reactive({
    page: 1,
    limit: 10,
    total: 0
})
const indexSort = (index) => {
    return (pageObj.page - 1) * pageObj.limit + index + 1
}
const subContent = (content) => {
    if (content.length > 28) {
        return content.substr(0, 28) + '...'
    }
    return content
}
const switchVal = (val) => {
    return !!val
}
const closeWords = async (param) => {
    console.log('param',param);
    const formdata = {
        account_message_id: param.account_message_id,
        status: param.status ? 1 : 0
    }
    try {
        const {data, msg} = await disabledWords(formdata)
        ElMessage({
            type: 'success',
            message: msg,
            duration: 1500,
            onClose: () => {
                says()
            }
        })
    } catch (e) {
        ElMessage.warning(e.message)
    }
}
const pageChange = (val) => {
    pageObj.page = val
    says()
}
const says = async () => {
    let param = {
        paging: 1,
        page: pageObj.page,
        pageSize: pageObj.limit,
    }
    if (form.selected !== '') {
        param.is_sensitive = form.selected
    }
    if (phone.value.trim().length > 0) {
        param.mobile = phone.value
    }
    if (form.disabled !== '') {
        param.status = form.disabled
    }
    const {data, msg} = await sayLists(param)
    console.log('data', data);
    tableData.value = data.list.map(item => {
        item.status = !!item.status
        return item
    })
    pageObj.total = data.total
}
onMounted(() => {
    says()
})
</script>
<style scoped lang="scss">
.words-main {
    &, & * {
        box-sizing: border-box;
    }
    .form-main {
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .data-main {
        width: 100%;
        height: calc(100% - 50px);
    }
}
</style>